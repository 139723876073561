.playVideo {
    background-color: #21272F;
}

.playVideo_auto {
    text-align: center;
}

.homeSlider {
    height: 60vh;
    width: 100%;
    background-color: #21272F;
    padding: 30px 0;
}

.swiper {
    width: 350px;
    height: 400px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -180px;
    margin-top: -150px;

}

.SwiperSlide {
    background-color: black !important;


}


.homeVideo {
    border-radius: 20px !important;
    height: 400px;
    margin-left: 70px;
}

.swiper-button-prev {
    color: aqua !important;
    height: 10px !important;
    width: 10px !important;
}

.swiper-button-next {
    color: aqua !important;
    height: 10px !important;
    width: 10px !important;
}

@media screen and (max-width:945px) {


    .atMobileDevice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .playVideo_auto {
        height: 350px;
        width: 250px;
        margin: 10px auto;
    }
}