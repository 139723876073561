.touch {
    background-color: #21272F;
    width: 100%;
    height: 110vh;
}

.responsiveContact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(2%);
}

.getInTOuch p {
    color: white;
    font-size: x-large;
}

.getInTOuch small {
    color: rgb(0, 140, 255);
    font-size: small;
    line-height: 0;
}

.socialLinks h4 {
    color: white;
    font-weight: 500;
}

.socialLinks a {
    text-decoration: none;
    font-weight: 700;
    color: rgb(0, 89, 123);
}

.touchForm h2 {
    color: white;
}

.formHeadingLabel,
#emailHelp {
    color: white;
}

@media screen and (max-width: 767px) {

    .responsiveContact {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(2%);
    }

    .touch {
        height: 120vh;
    }
}