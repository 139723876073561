.about {
    background-color: #21272F;
    width: 100%;
    height: 100vh;
}

.about_title .aboutIntro1 {
    color: white;
    font-weight: 600;
    font-size: 4rem;
}

.about_title .aboutIntro2 {
    color: white;
    font-weight: 100;
    font-size: 4rem;
}

.at575px img {
    border-radius: 30px !important;
}

.aboutDs p {
    color: white;
    text-align: justify;
}

@media screen and (max-width: 1187px) {

    #aboutForResponsive {

        height: 300px;
        width: 300px;
    }
}

@media screen and (max-width: 1000px) {

    .aboutDs p {
        font-size: small;
    }
}

@media screen and (max-width: 767px) {

    .aboutDs p {
        font-size: x-small;
    }

    #aboutForResponsive {

        height: 200px;
        width: 200px;
    }

    .about_title span {
        font-size: medium;
    }
}

@media screen and (max-width: 575px) {

    .aboutDs p {
        font-size: small;
    }

    .at575AboutHeading {
        text-align: center;
    }

    .at575px {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #aboutForResponsive {

        height: 250px;
        width: 250px;
    }

    .aboutDs {
        margin-top: 10px;
    }

    .hide575aboutdes {
        display: none;
    }
}

@media screen and (max-width: 415px) {

    .aboutIntro1,
    .aboutIntro2 {
        font-size: 2rem !important;
    }

    .about {

        height: 100%;
    }

    .aboutDs {
        text-align: center;
    }

    .atSmallDevice {
        margin: 0 !important;
    }

}

@media (max-height:932px) and (max-width: 430px) {

    .aboutIntro1,
    .aboutIntro2 {
        font-size: 2rem !important;
    }

    .about {

        height: 100%;
    }

    .aboutDs {
        text-align: center;
    }

    .atSmallDevice {
        margin: 0 !important;
    }

}