.upper {
    background-color: #21272F;
    width: 100vw;
}



body::-webkit-scrollbar {
    display: none;
}

#responseAtId {
    display: none;
}

.intro .intro1 {
    color: white;

}

.intro .intro2 {
    color: rgb(0, 195, 153);
    font-weight: 500;
    font-size: large;
}

#title {
    color: rgb(0, 195, 153);
    font-weight: 600;
    font-family: syne, sans-serif;
}

.subtitle h5 {
    color: rgb(0, 195, 153);

}

.description {
    text-align: justify !important;
    line-height: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.description span {
    color: white;
    font-size: small;
    font-family: 'Poppins', sans-serif;

}

.btn_set button {
    background-color: rgb(17, 201, 141);
    font-size: large;
    font-weight: 700;
    color: white;
    width: fit-content;
    box-shadow: 10px 10px 20px 10px rgb(28, 58, 60);
}

.btn_set button:hover {
    background-color: rgb(66, 235, 178);
    color: white;
    width: fit-content;
    box-shadow: 10px 10px 20px 10px rgb(28, 58, 60);
}

.diamond {
    display: flex;
}

.diamond .box_1 span {
    color: white;
    font-size: medium;
    font-weight: 500;
}

.diamondShape {
    display: flex;
}

.box_2 div,
.box_3 div,
.box_4 div {
    background-color: rgb(53, 103, 101);
    height: 40px;
    width: 40px;
    margin: 0 20px 0px 20px;
    transform: rotate(45deg);

    display: flex;
    justify-content: center;
    align-items: center;
}

.social_media {

    transform: rotate(-45deg);

}

.hexagon-container {

    /* Adjust the height to control the proportions */
    position: relative;

}


.clip_path {

    position: absolute;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

    height: 400px;
    width: 350px;
    background-color: rgb(0, 189, 149);


    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 80px;
    animation: floater 2s infinite;
    transition: ease 1s;
}

@keyframes floater {

    0%,
    100% {
        transform: translateY(-2%);
        transition: ease 1s;
    }

    50% {
        transform: translateY(2%);
        transition: ease 1s;
    }
}

#owner {
    height: 450px;
    width: 450px;
    position: absolute;
    top: 0px;
    left: -70px;

}

/* footer */
.footeer {
    background-color: #252d38;
    padding: 20px 0;
    width: 100vw;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}


.setLastBottom {
    position: absolute;
    right: 30px;


}

.foot {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLogo {
    height: 30px;
    margin: 0;
    width: 30px;
    padding: 0;
    border-radius: 50%;
    background-color: rgb(53, 103, 101);
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLogo a {
    margin-bottom: 5px;
}

.foot .socialLogo {
    margin: 0px 6px 0 6px;
}

.foot .companyName p {
    align-self: center;
    margin-top: 5px;
    cursor: pointer;

}

.foot .companyName p a {
    color: white
}

.foot .companyName p a:hover {
    color: grey
}

.foot .companyName p:hover {
    color: grey;
}

.foot .companyName p b {
    font-size: larger;
}

.meDeveloper {
    cursor: pointer !important;
    color: white;
}

.navFooter a {
    text-decoration: none;
    color: white;
    padding: 10px;
}

.navFooter a:hover {
    color: rgb(0, 189, 149);
    border-bottom: 1px solid rgb(0, 189, 149);
}

.diamond {
    display: none;
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 892px) {
    .clip_path {
        height: 300px;
        width: 250px;


    }

    #owner {
        height: 300px;
        width: 250px;
        top: 0px;
        left: -10px;
    }

    .description {
        width: 95%;
    }

    .diamond {
        display: block;
    }
}

@media screen and (max-width: 640px) {
    .clip_path {
        height: 270px;
        width: 200px;
    }

    #owner {
        height: 270px;
        width: 200px;
    }
}

#iconMobile {
    display: none !important;
}

@media screen and (max-width: 575px) {
    #responseAtId {
        display: block !important;
    }



    #iconMobile {
        display: flex !important;
    }

    .space {
        margin: 0 5px;
    }

    .setLastBottom {
        display: none !important;
    }

    .footerDeveloper {
        width: auto !important;
        margin: auto !important;
    }

    .clip_path {


        position: absolute;
        bottom: 5px;
        transform: translateX(-50%) !important;
        left: 50%;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

        height: 300px;
        width: 250px;
        background-color: rgb(0, 189, 149);


        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 30px 30px 40px 90px rgb(28, 58, 60);
        border-radius: 80px;

    }

    /* #animationFloatMobile {
        animation: floaterMobile 3s ease infinite;
    }

    @keyframes floaterMobile {
        0% {
            transform: translateY(-10%);
        }

        50% {
            transform: translateY(10%);
        }
    } */

    .responsiveDevice {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #owner {
        height: 300px;
        width: 250px;
        position: absolute;
        top: 0px;
    }

    #forsmallDevice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }



    .description {
        margin: auto;
        text-align: center;
    }

    .diamond {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .diamond .box_1 span {

        display: none;
    }


    .box_2 div,
    .box_3 div,
    .box_4 div {

        height: 30px;
        width: 30px;



    }
}

@media screen and (min-width: 425px) and (max-width: 575px) {
    .clip_path {
        animation: none !important;
    }

}