.project {
  background-color: #21272f;
}



.filter_btn {
  display: flex;
  align-items: center;
}

#dropdown-button-dark-example2 {
  background-color: rgb(17, 201, 141);
  font-size: large;
  font-weight: 600;
  color: white;
  margin: 10px 5px;
  border-radius: 7px;
  border: 0 !important;
  outline: 0 !important;
}

.dropdown-menu {
  background-color: black !important;
  border: 0 !important;
  margin-top: 10px !important;
  padding: 10px 5px;
}

.dropdown-button-dark-example2:hover {
  background-color: rgb(33, 75, 61) !important;
  color: white !important;
}

.dropdown-item.active {
  background-color: rgb(17, 201, 141);
}

.dropdown-item {
  margin: 4px 0;
}

.dropdown-item:hover {
  background-color: rgb(17, 201, 141) !important;
}

.nameProject {
  position: relative;
  bottom: 70px;
  left: -30px;
}

.nameProject h6 {
  color: white;
  font-weight: 700;
  font-size: larger;
  letter-spacing: 3px;
  line-height: 7px;
  font-family: cursive;
}

.nameProject span {
  color: white;
  display: inline-block !important;
  line-height: 20px;
}


.dropdown {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.project_list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.playVideo_auto1 video {
  padding: 0 !important;

  border-radius: 2rem;
  /* margin: 10px 10px; */
  width: 250px;
  cursor: pointer;


}



.project_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap
}

.modelVideo video {
  border-radius: 20px !important;
}



@media screen and (max-width:1199px) {
  .nameProject {
    position: relative;
    bottom: 70px;
    left: 0;
  }

  .playVideo_auto1 {

    height: 300px;
    width: 230px;
    /* You can adjust the value as needed */
  }

  .playVideo_auto1 video {

    margin-top: -88px !important;
  }
}

@media screen and (max-width:922px) {

  .modelData h3 {
    font-size: x-large;
  }

  .modelData p {
    font-size: small;
  }

  #modelHeight {
    width: 80% !important;
  }

  .modelVideo video {
    margin-top: -60px !important;
  }

  .playVideo_auto1 {

    height: 280px;
    width: 200px;
    /* You can adjust the value as needed */
  }

  .playVideo_auto1 video {

    margin-top: -105px !important;
  }
}

@media screen and (max-width:767px) {
  .playVideo_auto1 {


    margin: 100px 40px;
  }

  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project_list {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .playVideo_auto1 {

    height: 340px;
    width: 270px;
    /* You can adjust the value as needed */
  }

  .playVideo_auto1 video {

    margin-top: -68px !important;
  }

}

@media screen and (max-width:575px) {
  .playVideo_auto1 video {
    width: 900px !important;
  }

  .mobileView {
    text-align: center !important;
    font-size: 1.3rem;
  }

  .project_list {
    margin: 0 !important;
  }
}

@media screen and (max-width:443px) {

  .modelVideo video {
    height: 250px !important;
    width: 200px !important;
    margin: auto !important;
  }

  .modelData h3 {
    font-size: large;
  }

  .modelData p {
    font-size: x-small;
  }

  .modelBtn {
    height: 40px;
    width: 120px;
    font-size: small;
  }
}

@media screen and (max-width:400px) {





  .modelBtn {
    height: 30px;
    width: 70px;
    font-size: xx-small;
  }
}

@media screen and (max-width:362px) {





  .modelBtn {
    height: 30px;
    width: 60px;
    font-size: xx-small;

  }
}