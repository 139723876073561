#back_color_set {
    background-color: #21272F;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
}

.brandColor {
    color: rgb(0, 189, 149);
    font-size: 2.3rem;
    cursor: pointer;
}

.brandColor:hover {
    color: rgb(0, 189, 149);
}

.nav_name a {
    color: white;
    transition: all 0.4s;
}

.nav_name a:hover {
    color: rgb(0, 189, 149);
}

.navbar-toggler {

    background-color: aqua !important;
}

#navDesign a {
    text-decoration: none !important;
    margin: 0 20px;
}

@media screen and (max-width:525px) {
    .brandColor {
        font-size: 1.5rem !important;
    }
}