@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize;
}

a {
  text-decoration: none !important;
}

/* font-family: 'Poppins',
sans-serif; */