.brand1 {
  background-color: #21272f;
}

.brand_Title {
  color: white;
  font-weight: 500;
}

.at575BrandLogo {
  /* background-color: antiquewhite; */
  margin: 0 20px;
}

.at575BrandLogo img {
  height: 150px;
  width: 250px;
  /* margin: 20px 40px; */
  margin: auto;
  object-fit: contain;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 10rem;
}

.wrapper {
  position: absolute;
  display: flex;
}

.brandSection {
  display: flex;
  animation: swipe calc(var(--speed) * 2) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}



@media screen and (max-width: 575px) {
  .brand_Title {
    color: white;
    font-weight: 400;
    font-size: small;
  }

  .at575BrandLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .at575BrandLogo img {
    height: 100px;
    width: 150px;
    margin: 10px 0px 10px 0;
    margin: auto;
    object-fit: contain;
  }
}
