.Achievement {
    background-color: rgb(23, 28, 35);
}

.achiev_Heading h1 {
    color: white;
    font-weight: 900;
}



@media screen and (max-width: 575px) {

    .achiev_Heading {
        text-align: center;
    }

    .service_provide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .at575aboutAchivement {
        margin-left: 0 !important;
        margin-right: 0 !important;
        transform: translate(7%);

    }
}